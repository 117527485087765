import {
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import SpaceBarIcon from "@mui/icons-material/SpaceBar";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { map, startCase } from "lodash";

export function ProjectSummary({ props }) {
  const theme = useTheme();

  const { sortByKeys, sortByKey, setSortByKey, direction, setDirection } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: "15px",
        borderRadius: "8px",
        backgroundColor: theme.palette.grey["900"],
      }}
    >
      <Typography variant="body1">For each intersection, we show:</Typography>

      <List>
        <ListItem>
          <ListItemIcon>
            <ScatterPlotIcon />
          </ListItemIcon>
          <ListItemText
            primary="A graph showing the number of vehicles visible to the traffic camera"
            secondary="Critical to extracting valuable insights about city trends and traffic patterns"
          />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <AnalyticsIcon />
          </ListItemIcon>
          <ListItemText primary="The number of data points collected" secondary="Since the start of the project" />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <SpaceBarIcon />
          </ListItemIcon>
          <ListItemText
            primary="Statistics about gap sizes (in minutes) between data points"
            secondary="Measures the resillience of our distributed architecture"
          />
        </ListItem>
      </List>

      <Typography variant="body1" gutterBottom>
        Choose the sorting mode for the data:
      </Typography>

      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel>Sort By</InputLabel>
        <Select value={sortByKey} label="Sort By" onChange={(event) => setSortByKey(event.target.value)}>
          {map(sortByKeys, (sortByKey, i) => (
            <MenuItem key={i} value={sortByKey}>
              {startCase(sortByKey)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel>Direction</InputLabel>
        <Select value={direction} label="Direction" onChange={(event) => setDirection(event.target.value)}>
          <MenuItem value={"descending"}>Descending</MenuItem>
          <MenuItem value={"ascending"}>Ascending</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
