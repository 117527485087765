import { useEffect, useMemo, useState } from "react";
import Axios from "axios";
import { isEmpty, keys, reverse, slice, sortBy } from "lodash";
import { ungzip } from "pako";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Pagination from "@mui/material/Pagination";
import { Intersections } from "./components/Intersections";
import { LoadingState } from "./components/LoadingState";
import { ProjectSummary } from "./components/ProjectSummary";
import { Title } from "./components/Title";

const API_URL = process.env.REACT_APP_ENV === "dev" ? "http://localhost:3001" : process.env.REACT_APP_API_URL;
const DATA_URL = `${API_URL}/data`;
const ITEMS_ON_PAGE = 10;

const darkTheme = createTheme({ palette: { mode: "dark" } });

function App() {
  const [isReady, setIsReady] = useState(false);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = ~~(data.length / ITEMS_ON_PAGE);

  const [sortByKeys, setSortByKeys] = useState(["maxGap"]);
  const [sortByKey, setSortByKey] = useState("maxGap");
  const [direction, setDirection] = useState("descending");

  const visibleData = useMemo(
    () =>
      slice(
        direction === "descending"
          ? reverse(sortBy(data, `insights.${sortByKey}`))
          : sortBy(data, `insights.${sortByKey}`),
        (currentPage - 1) * ITEMS_ON_PAGE,
        currentPage * ITEMS_ON_PAGE,
      ),
    [data, currentPage, sortByKey, direction],
  );

  useEffect(() => {
    Axios.get(DATA_URL)
      .then((res) => res.data)
      .then((gzippedDatabase64) => {
        const gzippedData = atob(gzippedDatabase64);
        const gzippedDataArray = Uint8Array.from(gzippedData, (c) => c.charCodeAt(0));
        const ungzippedDataArray = ungzip(gzippedDataArray);
        const ungzippedData = new TextDecoder().decode(ungzippedDataArray);
        const data = JSON.parse(ungzippedData || "[]");

        setData(data);
        setSortByKeys(!isEmpty(data) ? keys(data[0]["insights"]) : []);
        setIsReady(() => true);
      });
  }, [sortByKey]);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />

      <Container maxWidth="lg" sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: "10px" }}>
        {!isReady ? (
          <LoadingState />
        ) : (
          <>
            <Title props={{ dataLength: data.length }} />

            <ProjectSummary props={{ sortByKeys, sortByKey, setSortByKey, direction, setDirection }} />

            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={(_, value) => setCurrentPage(value)}
              sx={{ mt: "20px" }}
            />

            <Intersections props={{ visibleData }} />

            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={(_, value) => setCurrentPage(value)}
              sx={{ mb: "20px" }}
            />
          </>
        )}
      </Container>
    </ThemeProvider>
  );
}

export default App;
