import { some } from "lodash";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { Chart as ChartJS, TimeScale } from "chart.js/auto";
import { useMemo } from "react";
import "chartjs-adapter-date-fns";
import { IntersectionChart } from "./IntersectionChart";
import { IntersectionHeader } from "./IntersectionHeader";
import { IntersectionInsights } from "./IntersectionInsights";

ChartJS.register(TimeScale);

export function Intersection({ props }) {
  const theme = useTheme();

  const { intersection, insights, chartData } = props;
  const [lng, lat] = intersection["location"];
  const hasPositiveCounts = useMemo(() => some(chartData, ([, count]) => count > 0), [chartData]);

  return (
    <Box sx={getIntersectionStyle(theme)}>
      <IntersectionHeader props={{ intersection, lat, lng }} />
      <IntersectionChart props={{ hasPositiveCounts, chartData }} />
      <IntersectionInsights props={{ insights }} />
    </Box>
  );
}

function getIntersectionStyle(theme) {
  return {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    mb: "30px",
    p: "15px",
    borderRadius: "8px",
    backgroundColor: theme.palette.grey["900"],
  };
}
