import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { map } from "lodash";

export function IntersectionHeader({ props }) {
  const { intersection, lat, lng } = props;

  return (
    <>
      <Typography variant="h5">{intersection["name"].replace(/&quot;/g, '"')}</Typography>

      {map(intersection["urls"], (url, i) => (
        <Link key={i} href={url}>
          {url}
        </Link>
      ))}

      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography variant="body1" gutterBottom sx={{ mr: "5px" }}>
          {lat}, {lng}
        </Typography>
        <Link href={`https://www.google.com/maps/place/${lat},${lng}`}>(open map)</Link>
      </Box>
    </>
  );
}
