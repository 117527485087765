export function IntersectionInsights({ props }) {
  const { insights } = props;

  return (
    <ul>
      <li>{insights["numEntries"]} data points</li>
      <li>Max gap: {insights["maxGap"]}m</li>
      <li>Min gap: {insights["minGap"]}m</li>
      <li>Avg gap: {insights["avgGap"]}m</li>
      <li>{insights["maxSeen"]} max vehicles seen</li>
      <li>Earliest date: {insights["earliestDate"]}</li>
      <li>Latest date: {insights["latestDate"]}</li>
    </ul>
  );
}
