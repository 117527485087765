import Box from "@mui/material/Box";
import { map } from "lodash";
import { Intersection } from "./Intersection";

export function Intersections({ props }) {
  const { visibleData } = props;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", mt: "20px", width: "100%" }}>
      {map(visibleData, (data, i) => (
        <Intersection props={data} key={i} />
      ))}
    </Box>
  );
}
