import { Typography } from "@mui/material";

export function Title({ props }) {
  const { dataLength } = props;

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Traffic Sense Data Insights
      </Typography>

      <Typography variant="h5" gutterBottom>
        Real-time, AI vehicle-detection results from {dataLength} traffic cameras in New York.
      </Typography>
    </>
  );
}
