import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { map } from "lodash";
import { Line } from "react-chartjs-2";

const NY_TIME_ZONE = "America/New_York"; // for now

export function IntersectionChart({ props }) {
  const { hasPositiveCounts, chartData } = props;
  return (
    <>
      {!hasPositiveCounts ? (
        <Typography variant="h6">No positive vehicle counts collected for this intersection.</Typography>
      ) : (
        <Box sx={{ height: "150px", width: "100%" }}>
          <Line data={getChartData(chartData)} options={getChartOptions()} />
        </Box>
      )}
    </>
  );
}

function getChartData(chartData) {
  const data = map(chartData, ([time, count]) => ({ x: time, y: count }));

  return {
    datasets: [
      {
        data,
        borderColor: "rgba(0,0,0,0)",
        backgroundColor: "cyan",
      },
    ],
  };
}

function getChartOptions() {
  return {
    elements: { point: { radius: 2 } },
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        yAlign: "bottom",
        callbacks: {
          title: (chart) => {
            const { dataIndex } = chart[0];
            const date = new Date(chart[0].dataset.data[dataIndex]["x"]);

            return date.toLocaleString("en-CA", { timeZone: NY_TIME_ZONE });
          },
        },
      },
    },
    scales: {
      x: {
        type: "timeseries",
        ticks: { display: true, autoSkip: true, minRotation: 45, maxRotation: 45 },
        grid: { display: false },
        time: {
          parser: (time) => time + 3 * 60 * 60 * 1000,
        },
      },
      y: { ticks: { display: true }, grid: { display: false } },
    },
  };
}
